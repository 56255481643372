/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Breadcrumb from 'components/shared/Breadcrumb'
import Content from 'components/shared/Content'
import ParseContent from 'components/shared/ParseContent'
import Search from 'components/Check/Search'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
        }
        content {
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const Template = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero small className="mb-lg-5" content={acf.banner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <section className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <Content
              className="text-center"
              content={acf.content.description}
            />
          </div>

          <div className="my-4 col-lg-10">
            <iframe
              style={{ height: 650 }}
              title="Doe de test"
              src="https://clearmind.speedtestcustom.com"
              className="w-100"
            />
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <Search />
        </div>
      </section>
    </Layout>
  )
}

export default Template
